// extracted by mini-css-extract-plugin
export var attachment = "_8k-a";
export var emailContainer = "_8k-4";
export var emailContentPaddedContainer = "_8k-b";
export var emailHeader = "_8k-6";
export var emailInfoIcon = "_8k-9";
export var emailShortenedSummary = "_8k-e";
export var infoDropdown = "_8k-0";
export var infoRowBoundedContent = "_8k-d";
export var internal = "_8k-5";
export var merchant = "_8k-8";
export var messageInputContainer = "_8k-h";
export var normal = "_8k-7";
export var replyCard = "_8k-f";
export var shadowDomWrapper = "_8k-3";
export var subtleButton = "_8k-1";
export var subtleButtonIconContainer = "_8k-2";
export var toggleViewRepliesButton = "_8k-c";
export var wrappingText = "_8k-g";
// extracted by mini-css-extract-plugin
export var container = "_2j-0";
export var error = "_2j-9";
export var label = "_2j-5";
export var light = "_2j-6";
export var md = "_2j-3";
export var sm = "_2j-2";
export var thin = "_2j-8";
export var thinBold = "_2j-7";
export var vertical = "_2j-4";
export var xs = "_2j-1";
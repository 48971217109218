// extracted by mini-css-extract-plugin
export var chip = "_2e-9";
export var chipLabel = "_2e-a";
export var close = "_2e-7";
export var closeIcon = "_2e-8";
export var error = "_2e-6";
export var infoTooltip = "_2e-2";
export var innerContainer = "_2e-1";
export var input = "_2e-b";
export var medium = "_2e-5";
export var outerContainer = "_2e-0";
export var small = "_2e-4";
export var xSmall = "_2e-3";
// extracted by mini-css-extract-plugin
export var centered = "_2g-6";
export var flexible = "_2g-5";
export var footer = "_2g-k";
export var footerBorder = "_2g-l";
export var header = "_2g-c";
export var headerBorder = "_2g-d";
export var headerDefault = "_2g-g";
export var headerMedium = "_2g-h";
export var headerPadding = "_2g-e";
export var iconAndTitle = "_2g-f";
export var large = "_2g-4";
export var largePadding = "_2g-a";
export var medium = "_2g-3";
export var mediumPadding = "_2g-9";
export var modal = "_2g-0";
export var modalContainer = "_2g-b";
export var noPadding = "_2g-7";
export var skinny = "_2g-1";
export var small = "_2g-2";
export var smallPadding = "_2g-8";
export var subtitle = "_2g-j";
export var title = "_2g-i";
// extracted by mini-css-extract-plugin
export var brand = "_37-4";
export var compact = "_37-7";
export var extraSmall = "_37-8";
export var icon = "_37-0";
export var large = "_37-1";
export var medium = "_37-2";
export var outline = "_37-9";
export var primaryDark = "_37-5";
export var primaryLight = "_37-3";
export var primaryModern = "_37-6";
// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_7l-e";
export var baseCustomerActivityCardArrowRightContainer = "_7l-d";
export var baseCustomerActivityCardContainer = "_7l-b";
export var baseCustomerActivityCardContainerHovered = "_7l-c";
export var customerActivityDataClaimIcon = "_7l-7";
export var customerActivityDataClaimIconContainer = "_7l-6";
export var customerActivityDataConversationIcon = "_7l-5";
export var customerActivityDataConversationIconContainer = "_7l-4";
export var customerActivityDataOrderIcon = "_7l-1";
export var customerActivityDataOrderIconContainer = "_7l-0";
export var customerActivityDataReturnIcon = "_7l-3";
export var customerActivityDataReturnIconContainer = "_7l-2";
export var customerActivityShoppingIconContainer = "_7l-8";
export var messageBubble = "_7l-9";
export var messageBubbleText = "_7l-a";
// extracted by mini-css-extract-plugin
export var actions = "_2p-0";
export var actionsContainer = "_2p-3";
export var border = "_2p-1";
export var collapse = "_2p-2";
export var compact = "_2p-b";
export var content = "_2p-4";
export var contentChild = "_2p-8";
export var fullscreen = "_2p-6";
export var header = "_2p-9";
export var headerContainer = "_2p-d";
export var hideHeaderBorder = "_2p-c";
export var hidePadding = "_2p-5";
export var noScrollX = "_2p-e";
export var noScrollY = "_2p-f";
export var optOutTopPadding = "_2p-7";
export var profile = "_2p-g";
export var profileContainer = "_2p-h";
export var profileImage = "_2p-i";
export var profileMain = "_2p-j";
export var profileSubtitle = "_2p-l";
export var profileTitle = "_2p-k";
export var scrolledTop = "_2p-a";
export var title = "_2p-m";
// extracted by mini-css-extract-plugin
export var grid = "_d-0";
export var span1 = "_d-2";
export var span10 = "_d-b";
export var span10L = "_d-z";
export var span10M = "_d-n";
export var span10Xl = "_d-1b";
export var span11 = "_d-c";
export var span11L = "_d-10";
export var span11M = "_d-o";
export var span11Xl = "_d-1c";
export var span12 = "_d-d";
export var span12L = "_d-11";
export var span12M = "_d-p";
export var span12Xl = "_d-1d";
export var span1L = "_d-q";
export var span1M = "_d-e";
export var span1Xl = "_d-12";
export var span2 = "_d-3";
export var span2L = "_d-r";
export var span2M = "_d-f";
export var span2Xl = "_d-13";
export var span3 = "_d-4";
export var span3L = "_d-s";
export var span3M = "_d-g";
export var span3Xl = "_d-14";
export var span4 = "_d-5";
export var span4L = "_d-t";
export var span4M = "_d-h";
export var span4Xl = "_d-15";
export var span5 = "_d-6";
export var span5L = "_d-u";
export var span5M = "_d-i";
export var span5Xl = "_d-16";
export var span6 = "_d-7";
export var span6L = "_d-v";
export var span6M = "_d-j";
export var span6Xl = "_d-17";
export var span7 = "_d-8";
export var span7L = "_d-w";
export var span7M = "_d-k";
export var span7Xl = "_d-18";
export var span8 = "_d-9";
export var span8L = "_d-x";
export var span8M = "_d-l";
export var span8Xl = "_d-19";
export var span9 = "_d-a";
export var span9L = "_d-y";
export var span9M = "_d-m";
export var span9Xl = "_d-1a";
export var stretch = "_d-1";
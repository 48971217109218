// extracted by mini-css-extract-plugin
export var box = "_7f-1";
export var checked = "_7f-5";
export var descriptionSpacer = "_7f-9";
export var disabled = "_7f-4";
export var icon = "_7f-6";
export var input = "_7f-0";
export var labelSpacer = "_7f-8";
export var medium = "_7f-3";
export var small = "_7f-2";
export var text = "_7f-7";
// extracted by mini-css-extract-plugin
export var actionButton = "_8z-1";
export var actions = "_8z-0";
export var copyableField = "_8z-2";
export var icon = "_8z-3";
export var overflowVisible = "_8z-4";
export var pillWrapper = "_8z-5";
export var productDetails = "_8z-7";
export var productPillsContainer = "_8z-8";
export var productTopDetails = "_8z-9";
export var products = "_8z-6";
export var returnDetails = "_8z-a";
export var strong = "_8z-b";
export var variantQuantityRow = "_8z-c";
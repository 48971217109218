// extracted by mini-css-extract-plugin
export var danger = "_3b-6";
export var lightPink = "_3b-a";
export var medium = "_3b-j";
export var nearSquare = "_3b-m";
export var neutralBlue = "_3b-8";
export var neutralYellow = "_3b-7";
export var noCapitalize = "_3b-1";
export var normal = "_3b-2";
export var pill = "_3b-0";
export var primary = "_3b-3";
export var primaryLight = "_3b-4";
export var shadow = "_3b-n";
export var small = "_3b-k";
export var solidBlack = "_3b-i";
export var solidGreen = "_3b-d";
export var solidLightBlue = "_3b-f";
export var solidNavy = "_3b-e";
export var solidOrange = "_3b-h";
export var solidPink = "_3b-g";
export var solidRed = "_3b-b";
export var solidWhite = "_3b-9";
export var solidYellow = "_3b-c";
export var success = "_3b-5";
export var xsmall = "_3b-l";
// extracted by mini-css-extract-plugin
export var bubbleContent = "_5e-0";
export var container = "_5e-e";
export var dark = "_5e-3";
export var dot = "_5e-8";
export var firstDot = "_5e-a";
export var left = "_5e-4";
export var light = "_5e-2";
export var lightCorrect = "_5e-1";
export var loadingBubble = "_5e-6";
export var loadingDot = "_5e-d";
export var loadingDotContainer = "_5e-7";
export var moveUpDown = "_5e-9";
export var right = "_5e-5";
export var secondDot = "_5e-b";
export var thirdDot = "_5e-c";
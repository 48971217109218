// extracted by mini-css-extract-plugin
export var createdDate = "_81-1";
export var divider = "_81-2";
export var headerDivider = "_81-3";
export var messagesCard = "_81-0";
export var messagesContainer = "_81-7";
export var messagesContainerWrapper = "_81-4";
export var pointerCapturingButtonBoxes = "_81-a";
export var showLeftPanelButton = "_81-9";
export var showMoreButton = "_81-c";
export var showMoreButtonContainer = "_81-b";
export var summaryTitle = "_81-6";
export var systemMessageCardWrapper = "_81-d";
export var topButtons = "_81-8";
export var withPadding = "_81-5";
// extracted by mini-css-extract-plugin
export var colorSquare = "_90-0";
export var danger = "_90-5";
export var lightPink = "_90-8";
export var neutralBlue = "_90-7";
export var neutralYellow = "_90-6";
export var normal = "_90-1";
export var primary = "_90-2";
export var primaryLight = "_90-3";
export var solidBlack = "_90-g";
export var solidGreen = "_90-b";
export var solidLightBlue = "_90-d";
export var solidNavy = "_90-c";
export var solidOrange = "_90-f";
export var solidPink = "_90-e";
export var solidRed = "_90-9";
export var solidYellow = "_90-a";
export var success = "_90-4";
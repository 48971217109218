// extracted by mini-css-extract-plugin
export var alternate = "_2x-h";
export var black = "_2x-3";
export var blue = "_2x-d";
export var blueLight = "_2x-c";
export var brand = "_2x-7";
export var error = "_2x-9";
export var gray = "_2x-6";
export var grayBlue = "_2x-b";
export var iconWrapper = "_2x-4";
export var indigo = "_2x-e";
export var orange = "_2x-g";
export var pink = "_2x-f";
export var primary = "_2x-0";
export var purple = "_2x-8";
export var success = "_2x-5";
export var warning = "_2x-a";
export var white = "_2x-1";
export var whiteSuccess = "_2x-2";
// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_91-0";
export var footer = "_91-2";
export var infoSection = "_91-3";
export var infoSectionContainer = "_91-4";
export var infoSectionTitleRow = "_91-5";
export var itemDetails = "_91-6";
export var line = "_91-7";
export var lineItemImage = "_91-1";
export var lineItems = "_91-8";
export var modalContent = "_91-9";
export var multiLineInfo = "_91-a";
export var orderName = "_91-b";
export var strong = "_91-c";
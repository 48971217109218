// extracted by mini-css-extract-plugin
export var alignCenter = "_57-b";
export var buttonContent = "_57-0";
export var cardList = "_57-1";
export var centerContent = "_57-2";
export var chevron = "_57-3";
export var closePanelButton = "_57-i";
export var conversationDetailContainer = "_57-4";
export var detailWrapper = "_57-5";
export var dropdownButton = "_57-c";
export var dropdownTitle = "_57-7";
export var hidden = "_57-8";
export var icon = "_57-9";
export var panel = "_57-g";
export var portalButtonsWrapper = "_57-a";
export var right = "_57-h";
export var tableSummaryContainer = "_57-6";
export var tableSummaryHeader = "_57-e";
export var threeDotsButton = "_57-d";
export var wide = "_57-f";
// extracted by mini-css-extract-plugin
export var aboveInput = "_84-0";
export var actionButton = "_84-5";
export var actionButtonContainer = "_84-1";
export var actionIcon = "_84-6";
export var aiInfoContainer = "_84-2";
export var attachmentWrapper = "_84-7";
export var bold = "_84-3";
export var checkIcon = "_84-8";
export var clickingRecipientBar = "_84-11";
export var commentThreadSwitch = "_84-a";
export var draftImage = "_84-b";
export var draftImageContainer = "_84-c";
export var draftImages = "_84-e";
export var editButton = "_84-g";
export var editor = "_84-f";
export var editorContainer = "_84-y";
export var editorErrorTooltipWrapper = "_84-9";
export var headerDivider = "_84-h";
export var icon = "_84-i";
export var info = "_84-j";
export var internal = "_84-n";
export var internalDivider = "_84-k";
export var internalMessage = "_84-l";
export var messageInputCard = "_84-m";
export var messageInputForm = "_84-o";
export var messageInputFormInternal = "_84-p";
export var options = "_84-q";
export var publicMessageCustomer = "_84-s";
export var publicMessageMerchant = "_84-r";
export var removeImageButton = "_84-d";
export var replyButtons = "_84-t";
export var showSignatureButton = "_84-u";
export var spinner = "_84-v";
export var verticalButtonDivider = "_84-4";
export var visibilitySelector = "_84-w";
export var warning = "_84-x";
export var wrappingText = "_84-z";
export var writingInternalNoteTopBorder = "_84-10";
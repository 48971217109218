// extracted by mini-css-extract-plugin
export var bubble = "_2t-0";
export var container = "_2t-3";
export var contrastBorder = "_2t-4";
export var greyOut = "_2t-2";
export var initialsWrapper = "_2t-1";
export var large = "_2t-9";
export var medium = "_2t-8";
export var mediumSmall = "_2t-7";
export var micro = "_2t-5";
export var small = "_2t-6";
export var tiny = "_2t-b";
export var xTiny = "_2t-a";
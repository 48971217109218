// extracted by mini-css-extract-plugin
export var buttons = "_4a-0";
export var buttonsActive = "_4a-h";
export var cancelConfirm = "_4a-1";
export var closeButton = "_4a-2";
export var column = "_4a-3";
export var container = "_4a-4";
export var detailHeader = "_4a-6";
export var detailTitle = "_4a-7";
export var detailsContent = "_4a-5";
export var header = "_4a-8";
export var headerWithClose = "_4a-9";
export var icon = "_4a-a";
export var infoIcon = "_4a-c";
export var informationContainer = "_4a-b";
export var sideDrawer = "_4a-d";
export var sideDrawerOpen = "_4a-e";
export var stickyFooter = "_4a-f";
export var typeOption = "_4a-g";
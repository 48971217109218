// extracted by mini-css-extract-plugin
export var animationContainer = "_8x-0";
export var footer = "_8x-1";
export var gray = "_8x-2";
export var lineItem = "_8x-3";
export var lineItemImage = "_8x-4";
export var lineItemInfo = "_8x-5";
export var lineItemText = "_8x-6";
export var lineItems = "_8x-7";
export var modalContent = "_8x-8";
export var options = "_8x-9";
export var orderName = "_8x-a";
export var refundAmount = "_8x-b";
// extracted by mini-css-extract-plugin
export var copyableField = "_8g-4";
export var customerBadge = "_8g-0";
export var customerInfoTitle = "_8g-2";
export var details = "_8g-1";
export var dropdownContainer = "_8g-5";
export var dropdownImage = "_8g-6";
export var editAssigneeForm = "_8g-a";
export var editButton = "_8g-3";
export var email = "_8g-7";
export var hidden = "_8g-d";
export var input = "_8g-8";
export var saveButton = "_8g-b";
export var shopifyIcon = "_8g-c";
export var username = "_8g-9";
// extracted by mini-css-extract-plugin
export var checkbox = "_3w-0";
export var circle = "_3w-3";
export var horizontalContainer = "_3w-7";
export var sm = "_3w-5";
export var switch0 = "_3w-2";
export var switchDisabled = "_3w-8";
export var switchEnabled = "_3w-1";
export var textSwitchContainer = "_3w-9";
export var textSwitchOption = "_3w-a";
export var textSwitchOptionSelected = "_3w-b";
export var verticalContainer = "_3w-6";
export var xs = "_3w-4";
// extracted by mini-css-extract-plugin
export var acitonButtons = "_6n-0";
export var block = "_6n-1";
export var blockContent = "_6n-7";
export var blockDescription = "_6n-8";
export var blockIcon = "_6n-9";
export var blockTitle = "_6n-6";
export var border = "_6n-a";
export var center = "_6n-k";
export var edge = "_6n-b";
export var edgeLabel = "_6n-c";
export var edgePath = "_6n-d";
export var error = "_6n-e";
export var flowchart = "_6n-f";
export var flowchartContent = "_6n-g";
export var highlighted = "_6n-3";
export var hover = "_6n-4";
export var icon = "_6n-h";
export var left = "_6n-l";
export var navButton = "_6n-i";
export var navButtons = "_6n-j";
export var right = "_6n-m";
export var selected = "_6n-2";
export var small = "_6n-5";
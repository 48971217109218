// extracted by mini-css-extract-plugin
export var animationContainer = "_7b-0";
export var conversationContainer = "_7b-1";
export var detailsContent = "_7b-2";
export var message = "_7b-3";
export var modalContent = "_7b-4";
export var modalFooter = "_7b-6";
export var modalHeader = "_7b-5";
export var name = "_7b-7";
export var optionContainer = "_7b-8";
export var optionTitle = "_7b-9";
export var pillContainer = "_7b-a";
export var selectedConversationsContainer = "_7b-b";
export var selectedOptionContainer = "_7b-c";
// extracted by mini-css-extract-plugin
export var actionButton = "_8j-0";
export var bubble = "_8j-2";
export var bubbleContext = "_8j-6";
export var bubbleItems = "_8j-7";
export var customer = "_8j-3";
export var divider = "_8j-8";
export var fileList = "_8j-9";
export var infoAbove = "_8j-a";
export var internal = "_8j-4";
export var lightActionButton = "_8j-1";
export var link = "_8j-b";
export var merchant = "_8j-5";
export var message = "_8j-c";
export var profilePicture = "_8j-e";
export var quillToolbarContainer = "_8j-f";
export var senderDate = "_8j-g";
export var senderDateCustomer = "_8j-h";
export var senderName = "_8j-d";
export var time = "_8j-i";
export var uploadedImage = "_8j-j";
export var username = "_8j-k";
export var xsmall = "_8j-l";
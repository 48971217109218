// extracted by mini-css-extract-plugin
export var addButton = "_5r-0";
export var border = "_5r-8";
export var check = "_5r-h";
export var danger = "_5r-6";
export var dropdownContainer = "_5r-1";
export var editModalBody = "_5r-j";
export var footerContainer = "_5r-i";
export var fullWidth = "_5r-a";
export var hide = "_5r-9";
export var icon = "_5r-3";
export var input = "_5r-7";
export var inputFlex = "_5r-b";
export var inputWrapper = "_5r-c";
export var manualFocus = "_5r-5";
export var optionButton = "_5r-4";
export var pillWrapper = "_5r-e";
export var pointer = "_5r-2";
export var showOverflow = "_5r-f";
export var tagsFlex = "_5r-d";
export var xButton = "_5r-g";
// extracted by mini-css-extract-plugin
export var active = "_8o-b";
export var arrow = "_8o-8";
export var arrowContainer = "_8o-4";
export var container = "_8o-0";
export var disabled = "_8o-5";
export var dot = "_8o-a";
export var dotContainer = "_8o-9";
export var leftArrowContainer = "_8o-6 _8o-4";
export var rightArrowContainer = "_8o-7 _8o-4";
export var slide = "_8o-3";
export var sliderContainer = "_8o-1";
export var sliderOverflow = "_8o-2";
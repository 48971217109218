// extracted by mini-css-extract-plugin
export var container = "_68-0";
export var content = "_68-3";
export var customerTitle = "_68-4";
export var empty = "_68-5";
export var gray = "_68-6";
export var header = "_68-7";
export var info = "_68-8";
export var loading = "_68-2";
export var loadingCard = "_68-9";
export var oneLine = "_68-a";
export var personName = "_68-e";
export var pillContainer = "_68-b";
export var selected = "_68-1";
export var text = "_68-c";
export var twoLines = "_68-d";
export var unread = "_68-f";
export var unreadIndicator = "_68-g";
// extracted by mini-css-extract-plugin
export var brand = "_2i-9";
export var button = "_2i-0";
export var chevron = "_2i-v";
export var chevronButton = "_2i-w";
export var content = "_2i-n";
export var danger = "_2i-g";
export var dangerOutlined = "_2i-h";
export var dark = "_2i-l";
export var darkTheme = "_2i-c";
export var extraSmall = "_2i-4";
export var fullWidth = "_2i-8";
export var ghost = "_2i-d";
export var gray = "_2i-t";
export var icon = "_2i-p";
export var iconButton = "_2i-s";
export var iconButtonBorder = "_2i-x";
export var iconButtonBorderDark = "_2i-y";
export var iconButtonBorderLight = "_2i-z";
export var iconLeft = "_2i-q";
export var iconRight = "_2i-r";
export var large = "_2i-1";
export var light = "_2i-m";
export var medium = "_2i-2";
export var micro = "_2i-5";
export var nano = "_2i-7";
export var outlined = "_2i-k";
export var pending = "_2i-a";
export var primary = "_2i-i";
export var shadow = "_2i-o";
export var small = "_2i-3";
export var solidLight = "_2i-e";
export var solidLightBrand = "_2i-f";
export var spinner = "_2i-u";
export var transparent = "_2i-j";
export var warning = "_2i-b";
export var wideNano = "_2i-6";
// extracted by mini-css-extract-plugin
export var active = "_1o-d";
export var blink = "_1o-e";
export var container = "_1o-5";
export var controls = "_1o-0";
export var controlsRight = "_1o-1";
export var fakeCursor = "_1o-c";
export var hidden = "_1o-b";
export var quillContainerSmall = "_1o-7";
export var quillEditor = "_1o-6";
export var quillEditorSmall = "_1o-8";
export var quillFormatButtons = "_1o-a";
export var quillToolbar = "_1o-9";
export var removePadding = "_1o-2";
export var state = "_1o-3";
export var stateTitle = "_1o-4";
// extracted by mini-css-extract-plugin
export var actionButton = "_60-t";
export var buttonContainer = "_60-0";
export var buttonContainer2 = "_60-1";
export var buttonSection = "_60-2";
export var details = "_60-3";
export var dropdownItem = "_60-5";
export var dropdownItemsContainer = "_60-4";
export var fileInput = "_60-r";
export var headerButton = "_60-6";
export var icon = "_60-7";
export var input = "_60-8";
export var macroListItem = "_60-9";
export var mainContent = "_60-a";
export var microText = "_60-b";
export var modal = "_60-w";
export var optionsButton = "_60-c";
export var paperclip = "_60-s";
export var pillContent = "_60-d";
export var preview = "_60-e";
export var preview2 = "_60-f";
export var quillEditor = "_60-q";
export var selectedMacro = "_60-g";
export var sidebar = "_60-h";
export var sidebarHeader = "_60-j";
export var sidebarList = "_60-i";
export var sidebarListItem = "_60-k";
export var sidebarSearch = "_60-l";
export var snoozeDurationWrapper = "_60-v";
export var statusPillContainer = "_60-m";
export var tagsContainer = "_60-n";
export var unselectedMacro = "_60-o";
export var variable = "_60-u";
export var wrapper = "_60-p";
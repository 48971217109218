// extracted by mini-css-extract-plugin
export var defaultWrapper = "_7j-3";
export var downloadButton = "_7j-4";
export var fileContainer = "_7j-7";
export var fileInfo = "_7j-5";
export var fileInfoWidthRestricted = "_7j-6";
export var fileType = "_7j-1";
export var fileTypeName = "_7j-2";
export var image = "_7j-0";
export var link = "_7j-9";
export var overflowEllipsis = "_7j-8";